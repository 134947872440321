@import './scss/_variables.scss';

.ant-form-item-label {
    padding: 0 !important;
    margin-bottom: 4px;
}

.ant-form-item {
    margin-bottom: 16px;
}

.ant-form-item-label>label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5F6467;
    height: auto;
    padding: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-checkbox-checked:hover,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--primary-color) !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color) !important;
}

.ant-radio-checked .ant-radio-inner:after {
    background-color: var(--primary-color);
}

.ant-radio:hover .ant-radio-inner {
    border-color: var(--primary-color);
}

.antd-switch {
    background: #C1D6DD !important;

    .ant-switch-checked {
        background: var(--primary-color) !important;
        border-radius: 49px;
    }
}

.ant-input,
.ant-select {
    border-color: $color-grey-input;

    &:hover,
    &:focus {
        border-color: var(--primary-color) !important;
        // border: none !important;
        box-shadow: none;
    }

    .ant-input-status-error {
        border-color: $color-red-input-error;
        box-shadow: none;
    }

    .ant-input-status-warning {
        border-color: $color-orange-input-warning;
        box-shadow: none;
    }
}

.ant-spin-dot-item {
    background-color: var(--primary-color);
}

.ant-spin-text {
    color: var(--primary-color);
}

.btn-loader {

    .ant-spin-sm {
        font-size: 10px !important;
    }

    .ant-spin-dot {
        height: 12px;
        width: 12px;
    }

    .ant-spin-dot-item {
        background-color: #fff;
    }
}


.ant-select-selector:hover,
// .ant-select-selector:focus-within,
.ant-picker:hover,
.ant-picker-focused {
    border: 1px solid var(--primary-color);
    box-shadow: none;
}

.ant-select-item-option-active,
.ant-select-item-option-selected,
.ant-select-item-option:hover {
    background-color: var(--primary-color-light) !important;
}

.ant-modal-body {
    padding: 40px 32px 64px 32px;

    z-index: 9999;


    @media screen and (max-width: 991px) {
        padding: 16px 16px;
        // min-height: 50vh;
        // height: 90vh;
    }
}

.ant-modal-content {
    border-radius: 8px;
}

.edit-modal {
    .ant-drawer-footer {
        border-top: none;
    }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-collapse {
    background-color: transparent;
    border: none;
    border-radius: 0;
}

.ant-collapse-item {
    border-radius: 4px !important;
    border: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED !important;
    margin-bottom: 24px;
}

.ant-notification-notice {
    background-color: $color-dark-grey;
}

.ant-notification-notice-close-icon,
.ant-notification-notice-description,
.ant-notification-notice-message,
.ant-notification-notice-icon-success {
    color: white !important;
}

.ant-picker-cell-in-view .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: var(--primary-color)
}

.ant-picker-month-btn,
.ant-picker-decade-btn,
.ant-picker-year-btn,
.ant-picker-today-btn {
    color: var(--primary-color);

    &:hover {
        color: var(--primary-color) !important;
        text-decoration: underline;
    }
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-item-active,
.ant-pagination-item-link:hover,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-item:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);

    a {
        color: var(--primary-color);
    }
}

.ant-drawer-header {
    border: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;

}

.ant-select-disabled .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
}

.ant-btn:hover {
    color: var(--primary-color) !important;
    text-decoration: none !important;
}

.w-addon {
    .ant-input {
        border-left: none !important;
        border-radius: 0px 4px 4px 0px !important;
    }
}

// .w-addon:hover~.ant-input-group-addon,
// .w-addon:active~.ant-input-group-addon {
//     border-color: var(--primary-color) !important;
//     background-color: red;
// }

.ant-input-number {
    border-radius: 0 4px 4px 0 !important;
}

.ant-input-number-group-addon,
.ant-input-group-addon {
    background-color: white;
    border-radius: 4px 0 0 4px !important;
    border: 1px 0 1px 1px solid #E9ECEF !important;
}

.ant-input-number-group-wrapper {
    width: 100%;
}

.ant-input,
.ant-select-selector,
.ant-picker-input,
.ant-input-number,
.ant-picker,
.ant-select {
    box-shadow: none !important;

    &:focus,
    &:hover,
    &:focus-within {
        border-color: var(--primary-color) !important;
    }
}

.super {
    background-color: #292D30;
    color: #EFA934;
    border-radius: 16px;
    padding: 4px 8px;
}

.ant-btn {
    border-radius: 4px;
    // font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &.ant-btn-primary {
        // padding: 8px 16px;
        border-radius: 4px;
        border: none;
        background-color: var(--primary-color) !important;
        color: white;
        min-width: 80px;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-color);
            color: white !important;
        }
    }

    &.ant-btn-default {
        border-radius: 4px;
        // padding: 8px 16px;
        border: 1px solid var(--primary-color);
        box-sizing: border-box;
        background-color: white;
        color: var(--primary-color);
        min-width: 80px;

        &:hover {
            background-color: var(--primary-color);
            color: white !important;
            cursor: pointer;
        }
    }
}

.super {
    background-color: #292D30;
    color: #EFA934;
    border-radius: 16px;
    padding: 4px 8px;
}

.ant-alert{
    padding: 12px 24px !important;
}

.ant-alert-info {
    background-color: #E3EFFF !important;
    border: none;
}