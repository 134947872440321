@import './scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');


html {
  scroll-behavior: smooth;
}

.card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $color-black;
  margin-bottom: 4px;
}

.layout {
  min-height: 100vh;
  background-color: #FAFAFA;
}

.icon {
  color: var(--primary-color) !important;
}

.icon-invalid {
  color: $color-red-input-error !important;
}

.loader-div {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  text-align: center;
}

.loader {
  padding: 50px;
  border-radius: 4px;
}

.login-layout {
  font-family: 'Inter';
  font-style: normal;
  min-height: 100vh;
  background-color: $color-login-background;
  position: relative;

  &.with-full-error , &.no-user-found {
    background-color: #fff;
  }
}


.main-app-container {
  padding: 0px 80px;

  @media screen and (max-width: 991px) {
    padding: 0px 24px 48px 24px;
  }
}

.container {
  padding: 80px 0px 50px 0px;


  @media screen and (max-width: 991px) {
    padding: 80px 0px 30px 0px !important;
  }

  .navbar-wrapper {
    width: 100%;
    max-width: 224px;
    z-index: 1;
    max-height: calc(100% - 80px);

    @media screen and (min-width: 992px) and (max-width: 1440px) {
      max-width: 80px;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .router {
    width: calc(100% - 224px);

    @media screen and (min-width: 992px) and (max-width: 1440px) {
      width: calc(100% - 80px);
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0 0px;
    }


    .router-component {
      width: 100%;
      margin: 0 auto;

      @media screen and (max-width: 991px) {
        max-width: 640px;
      }
    }
  }
}

.component-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding: 32px 0 24px 0;
}

.cta-icon {
  cursor: pointer;
}

.icon-plus-text {
  color: var(--primary-color);
  margin: 20px 0px;
  cursor: pointer;
}

.summary {
  background: #FFFFFF;
  border: 1px solid #F5F8FF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  margin-bottom: 44px;

  .claim-summary-card {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px 20px;
    margin-top: 16px;

    .title {
      margin-top: 8px;
    }
  }

  .card {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid $color-base-grey;
    padding: 16px 20px;
  }

  @media screen and (max-width: 991px) {
    background: none;
    margin-bottom: 48px;
    padding: 0px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-secondary-text;
  }

  .content {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $color-primary-text;

    @media screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 28px;

    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }
}

.margin-top {
  margin-top: 24px !important;
}

.margin-bottom {
  -webkit-margin-bottom-collapse: 24px;
  margin-bottom: 24px !important;
}

.margin-left {
  margin-left: 20px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.margin-right {
  margin-right: 20px !important;
}

.error-backend {
  margin-top: -16px;
  margin-bottom: 16px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-16 {
  margin-top: 12px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-12 {
  margin-top: 12px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-64-web {
  @media screen and (min-width: 992px) {
    margin-top: 64px !important;
  }
}

.mt-64 {
  margin-top: 64px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.color-red {
  color: $color-red-input-error;
}

.cta {
  color: var(--primary-color) !important;
  cursor: pointer;

  &.disabled {
    color: $color-base-grey !important;
    cursor: not-allowed;
  }

}

.primary-bg {
  background-color: var(--primary-color) !important;
}

.ml-8 {
  margin-left: 8px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.overflow-menu {
  background-color: white;
  border: 1px solid #C9CACD;
  box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
  border-radius: 4px;
  width: 240px;
  padding: 8px 0;

  .item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4B5054 !important;
    padding: 8px 16px;
    width: 100%;
  }

  .item:hover {
    background-color: $color-overflow-hover;
    cursor: pointer;
  }
}


.loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

a,
a:visited,
a:hover,
a:active {
  &.clean-anchor {
    text-decoration: none;
    color: inherit;
  }
}

.no-otp {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.resend-otp {
  font-size: 12px;
}

.otp-timeout {
  font-size: 12px;
  color: $color-red-input-error;
}

.text-between-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #C9CACD;
  line-height: 0.1em;
  margin: 16px 0 24px;
}

.text-between-line span {
  background: #fff;
  padding: 0 10px;
  font-weight: 400;
  color: $color-secondary-text;
}

.empty-table {
  padding: 20px;

  .text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: $color-grey;
  }
}

.float-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.dropdown {
  padding: 0px 16px !important;
}

.margin-top-zero {
  margin-top: 0px !important;
}

.padding-zero {
  padding: 0px !important;
}

.pointer {
  cursor: pointer !important;
}

.center-height {
  height: calc(100vh - 80px);
  margin: auto 0;
}

.error-screen {
  color: $color-primary-text;
  // background: #fff;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  text-align: center;

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  @media screen and (max-width: 991px) {
    max-width: 90vw;

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    .ant-image {
      display: flex;
      justify-content: center;
      align-items: center;
     
    .image {
        width: 320px;
      }
    }
  }


  .cta {
    margin-top: 48px;

    .button-primary {
      background-color: var(--primary-color) !important;
      color: white !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;

      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
}

.no-user-found {
  padding: 20px;

  .image {
    width: 70%;
    max-width: 520px;
  }

  .error-screen .button-primary {
    color: #000 !important;
    border: 1px solid #000 !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }

}

.not-on-phone {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

.not-on-web {
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}

.color-hyperlink-blue {
  color: $color-hyperlink-blue;
}

.web {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.mobile {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.summary-divider {
  height: 46px;

  @media screen and (max-width: 1221px) {
    height: 92px;
  }
}

.add-dependent {
  margin-top: 32px;
}

.margin-on-phone {
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
}

.show-on-web {
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.show-on-mobile {
  @media screen and (min-width: calc($mobile-breakpoint + 1px)) {
    display: none;
  }
}

.help-on-hover {
  cursor: help;
}

.svg-primary svg path {
  fill: var(--primary-color);
}

.text-wrap {
  word-wrap: break-word;
}

.ghost-input {
  border: none;
  padding: 4px 0;
  background: transparent;

  &:disabled {
    background: transparent;
    cursor: default;
    color: #000000D9;
  }
}

.claim-documents {

  .table-component{
    tr.ant-table-row.ant-table-row-level-0.header-row > td{
      background-color: var(--primary-color-light) !important;
    }
  }
  .ant-table-tbody>tr>td {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 10px;
    overflow-wrap: break-word !important;
    white-space: pre-wrap !important;
    color: #4B5054;
  }

  .title {
    margin: 42px 0 24px 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: black;
  }

  .required::after {
    color: red;
    content: "*";
    margin-left: 4px;
  }

  .tabs {
    margin-top: 32px;
  }

  .information {
    color: #4B5054;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 36px;
  }

  .alert-container {
    border-radius: 4px;
    background: #E3EFFF;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .alert-cta {
      color: $color-hyperlink-blue;
      text-decoration: underline;
    }
  }

  line-height: 14px;

  &.mobile-table {
    .table-component .ant-table-thead>tr>th {
      padding: 8px 24px;
    }

    .table-component .ant-table-tbody>tr>td {
      font-size: 14px;
      line-height: 20px;
      padding: 0px;
      overflow-wrap: normal;
      white-space: nowrap;
      color: #4B5054;
      font-weight: 400;
      letter-spacing: 0.3px;
    }
  }
}

.cta-with-icon {
  svg path {
    fill: var(--primary-color);
  }
}

.filter-tags {
  padding: 4px 12px;
  background: #FFFFFF;
  border: 1px solid var(--primary-color);
  border-radius: 12px;

  .filter {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: var(--primary-color);
  }

  .remove-icon {
    color: var(--primary-color);
    margin-left: 16px;
    height: 8px;
    width: 8px;
  }
}

.table-filter-drawer {

  .drawer-header {
    padding: 26px 32px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;

    .filter-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #343A40;
    }
  }

  .active-filter-tags {
    margin: 24px 30px;
  }

  .drawer-footer {
    margin-top: 24px;
    padding: 20px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .ant-collapse-header {
    padding: 14px 32px !important;
    background-color: #E9F2F2;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343A40 !important;
  }

  .ant-checkbox+span {
    padding: 0 12px !important;
  }

  .ant-collapse-content-box {
    padding: 16px 24px 16px 32px !important;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 32px !important;
  }
}

.text-success {
  color: var(--primary-color);
}

.text-warning {
  color: $color-orange-input-warning;
}

.strong {
  font-weight: 500;
}

.last-synced {
  color: #93959b;
  font: normal 400 14px/20px 'Inter';
}

.dangerous_html,
.dangerous_html * {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: 'Inter' !important;
  background-color: transparent !important;
  color: #5A6474 !important;
}

.dangerous_html a,
.dangerous_html a > span {
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: var(--primary-color) !important;
  // text-decoration: underline;
}

.editable-input {
  .ant-input {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-input-group-addon {
    border-radius: 0 8px 8px 0 !important;
  }
}
